jQuery(function($) {
  var $navbar = $('#main-navbar');
  var $search = $navbar.find('.navbar-search');
  var $userMenuToggle = $('#navbar-userspace');
  var $menuContent = $('.menu-content');
  var $chevronIcon = $userMenuToggle.find('.icon');


  $userMenuToggle.on('click', function(ev) {
    ev.stopPropagation();
    $menuContent.toggleClass('menu-visible');

    if ($menuContent.hasClass('menu-visible')) {
      $chevronIcon.removeClass('icon-chevron-down-o').addClass('icon-chevron-up-o');
    } else {
      $chevronIcon.removeClass('icon-chevron-up-o').addClass('icon-chevron-down-o');
    }
  });

  $(document).on('click', function() {
    if ($menuContent.hasClass('menu-visible')) {
      $menuContent.removeClass('menu-visible');
      $chevronIcon.removeClass('icon-chevron-up-o').addClass('icon-chevron-down-o');
    }
  });

  $menuContent.on('click', function(ev) {
    ev.stopPropagation();
  });

  $navbar.find('form.search').on('submit', function(ev) {
    var $q = $(this).find('[name=q]');

    if ($q.val().trim() === '') {
      ev.preventDefault();
      $q.focus();
    }
  });

  $search.find('.search-toggle').on('click', function() {
    var $this = $(this);
    var $search = $this.closest('.navbar-search');

    var $prev = $navbar.find('.navbar-header');
    var left = $prev.outerWidth() + $prev.position()['left'];
    var width = $this.position()['left'] + $this.outerWidth() - left;

    $search.css({
      left: left,
      width: width,
    });

    $navbar.find('#main-menu').css('visibility', 'hidden');

    $search.addClass('active');
    $search.find('[name=q]').focus();
    $(window).one('resize', closeSearchForm);
  });

  $search.find('button.close').on('click', function(ev) {
    closeSearchForm();
    ev.preventDefault();
  });

  $search.find('[name=q]').on('keyup', function(ev) {
    if (ev.which == 27) {
      closeSearchForm();
    }
  });

  function closeSearchForm() {
    $search.removeClass('active');
    $navbar.find('#main-menu').css('visibility', 'visible');

    $search.css({
      left: '',
      width: '',
    });
  }
});
